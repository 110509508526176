@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.compact.less";
@import "../../frontend-shared/src/styles/nz-variables.less";
@import "../../frontend-shared/src/styles/customization";


.clickable {
    cursor: pointer;
}

.ant-space.ant-space-vertical {
    display: flex;
}

.ant-table {
    overflow: auto;
}

.ant-table-column-title {
    white-space: nowrap;
}

.ant-popover-inner-content {
    .ant-menu.ant-menu-vertical {
        margin: 0 @popover-padding-horizontal * -1;
    }

    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right
    .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
        padding: 0 @popover-padding-horizontal;
    }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border: none !important;
}

.section-title{
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-family: Ferrara;
    font-weight: bold;
    white-space: pre-wrap;
}
.left-border::before { 
    content: "";
    height: 30px;
    width: 4px;
    background-color: #0A46FC;
    display: inline-block;
    margin-right: 12px;
    border-radius: 2px;
}

.ant-form-vertical .ant-form-item {
    flex-wrap: nowrap;
}
.ant-descriptions-view {
    overflow: hidden;
}

.ant-card-type-inner .ant-card-extra {
    padding: 11px 0px;
}


.cwt-map-marker-label {
    background: white;
    box-shadow: var(--cwt-black-shadow);
    padding: 0rem 0.4rem 0rem 0.4rem;
    transform: translateY(27px);
    border-radius: 8px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}