@import '../../../../../node_modules/quill/dist/quill.snow.css';

@quill-size: @font-size-base * 1.666667;

quill-editor {
    display: block !important;
    border-radius: @border-radius-base;
    transition: all 0.3s @ease-in-out, height 0ms;
    border-color: @border-color-base !important;
}

.ql-snow.ql-toolbar {
    padding: 8px;
    border-top-left-radius: @border-radius-base;
    border-top-right-radius: @border-radius-base;
}

.ql-snow.ql-container {
    border-bottom-left-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
}

.ql-snow.ql-toolbar,
.ql-container,
.ql-toolbar,
.ql-snow.ql-container {
    border-color: @border-color-base !important;
    transition: border-color 0.3s @ease-in-out;
}


.ql-snow.ql-container .ql-editor {
    padding: 8px;
    min-height: 150px;
}

.ql-snow.ql-toolbar button, 
.ql-snow .ql-toolbar button {
    width: @quill-size;
    height: @quill-size;
    padding: 2px;
}

.ql-snow.ql-toolbar .ql-formats {
    margin-right: 4px;
}

.ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
    width: @quill-size;
}

.ql-snow .ql-color-picker .ql-picker-label, .ql-snow .ql-icon-picker .ql-picker-label {
    padding: 2px;
    border: none !important;
}

.ql-snow .ql-picker {
    height: @quill-size;
    font-size: @font-size-base;
}

.ql-snow .ql-picker-label {
    padding-left: 2px;
    padding-right: 2px;
}

.ql-snow .ql-picker-label::before {
    line-height: @font-size-base;
}

quill-editor.fill-card {
    height: 100%;
    display: flex !important;
    flex-direction: column;

    > .ql-toolbar {
        flex-shrink: 0;
    }

    > .ql-container {
        flex: 1;
    }
}

quill-editor.ng-touched.ng-invalid {
    > .ql-toolbar,
    > .ql-container {
        border-color: @error-color !important;
    }
}

quill-editor[disabled] {
    background-color: @input-disabled-bg;
    color: @input-disabled-color;
    cursor: not-allowed;

    .ql-toolbar {
        pointer-events: none;
    }
}